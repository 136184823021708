.terminal {
  background-color: #000;
  color: #ffffff;
  font-family: monospace;
  padding: 0px 10px 10px 10px;
  height: 100vh;
  overflow-y: scroll;
  cursor: text;
  /* padding-bottom: 30px; */
}
.About {
  font-family: "Orbitron", sans-serif;
}
.historyItem {
  margin-bottom: 5px;
  text-align: left;
}

.inputLine {
  display: flex;
}

.input {
  background-color: #000;
  color: #ffffff;
  border: none;
  outline: none;
  flex: 1;
  caret-shape: underscore;
}
.Name {
  color: #00ffff;
}